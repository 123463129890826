import { useMatches } from '@remix-run/react'
import { useMemo } from 'react'
import type { RootLoaderData } from '~/root'

const useRootData = () => {
  const matches = useMatches()
  const rootMatch = useMemo(() => matches.find(match => match.pathname === '/'), [matches])

  const data = rootMatch?.data as RootLoaderData

  return data
}

export default useRootData
